import React, { useEffect, useState } from 'react';
import Header from "components/Header/Header.js";
import HomeFooter from "components/Footer/HomeFooter.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import { colors } from '../components/Theme/WebTheme';
import Download from "../components/Download";
import { useSelector } from "react-redux";

const dashboardRoutes = [];

export default function LandingPage(props) {
  const auth = useSelector(state => state.auth);
  const { ...rest } = props;
  const [role, setRole] = useState();

  useEffect(() => {
    if (auth && auth.profile) {
      if (auth.profile.uid) {
        let role = auth.profile.usertype;
        setRole(role);
      }
    }
  }, [auth]);

  return (
    <div style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      minHeight: '100vh', 
      backgroundColor: colors.LandingPage_Background, 
      margin: '-8px' 
    }}>
      <Header
        color="black"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        {...rest}
      />
      <div style={{ flex: 1, paddingTop: '80px' }}> {/* Adjust padding as needed */}
        <Download />
      </div>
      <HomeFooter />
    </div>
  );
}
